import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibPropriedade from '../biblioteca/bibPropriedade';
import { Conexao } from '../biblioteca/conexao';
import { FiltroComponent } from '../essencial/filtro/filtro.component';
import { ModalNotificacao } from '../essencial/modal/modal.notificacao';
import { Grupo } from '../interface/grupo';
import { Ordem } from '../interface/ordem';
import { Criterio } from '../modelo/criterio';
import { Empresa } from '../modelo/empresa';
import { Filtro } from '../modelo/filtro';
import { Identificacao } from '../modelo/identificacao';
import { Relatorio } from '../modelo/relatorio';
import { RelatorioAdicional } from '../modelo/relatorioAdicional';
import { Resultado } from '../modelo/resultado';
import { Utilitario } from '../modelo/utilitario';
import { DataPipe } from '../utilitario/data.pipe';
import { Util } from '../utilitario/util';
import { UtilNotificacao } from '../utilitario/util.notificacao';
import { UtilSessao } from '../utilitario/util.sessao';

@Component({
  selector: 'relatorioLayout',
  templateUrl: 'relatorioLayout.component.html',
  styleUrls: ['./relatorioLayout.component.css'],
})
export class RelatorioLayoutComponent {
  public _relatorio: Relatorio;
  public campoId: number = 0;
  public nomeAdicional: string = '';
  public utilitarios: Utilitario[] = [];
  public modal: boolean = false;

  @Input()
  set relatorio(relatorio: Relatorio) {
    if (relatorio) {
      this._relatorio = relatorio;
      this.utilSessao.setIdentificacao(new Identificacao('idRelatorio', relatorio.id));
      this.setTituloAbaNavegador();
    }
  }
  public _empresa: Empresa;
  @Input()
  set empresa(empresa: Empresa) {
    this._empresa = empresa;
    const conexao: Conexao = new Conexao(this.utilSessao);
    this._empresa.fotoEmpresa = conexao.caminhoAwsS3 + this._empresa.id + '/empresa/' + this._empresa.id + '.jpg';
  }

  public _relatorioAdicionais: RelatorioAdicional[];
  @Input()
  set relatorioAdicionais(relatorioAdicionais: RelatorioAdicional[]) {
    this._relatorioAdicionais = relatorioAdicionais;
    relatorioAdicionais.forEach((relatorioAdicional) => {
      if (relatorioAdicional.usaRelatorioAdicionalEmpresa) {
        this.setRelatorioAdicional(relatorioAdicional, false);
      }
    });
    this.setIdRelatorio();
    this.definirApelidoDoAdicional();
  }

  public _ordens: Ordem[] = [];
  @Input()
  set ordens(ordens: Ordem[]) {
    ordens.forEach((ordem, i) => {
      ordem.id = i + 1;
    });
    this._ordens = ordens;
  }

  public _grupos: Grupo[] = [];
  @Input()
  set grupos(grupos: Grupo[]) {
    grupos.forEach((grupo, i) => {
      grupo.id = i + 1;
    });
    this._grupos = grupos;
  }

  public _filtros: Filtro[] = [];
  @Input()
  set filtros(filtros: Filtro[]) {
    if (filtros != undefined) {
      filtros.forEach((filtro) => {
        this.utilSessao.getMenuEmpresa().forEach((menuEmpresa) => {
          if (filtro.rotulo?.toLocaleLowerCase() == menuEmpresa.menu.toLocaleLowerCase()) {
            filtro.rotulo = menuEmpresa.apelido;
          }
          if (filtro.rotulo?.toLocaleLowerCase() == bibDialogo.tipoLancamento.toLocaleLowerCase() && !this.utilSessao.getExisteTipoLancamentoContabil()) {
            filtro.visualizacaoInicial = false;
          }
        });
      });
      this._filtros = filtros;
      this.setIdRelatorio();
    }
  }
  @Input() relatorioTituloAdicional: string;
  @Input() apresentaEmpresa: boolean = false;
  @Output() adicionalEmt = new EventEmitter<RelatorioAdicional>();
  @Output() filtroEmt = new EventEmitter<Criterio[]>();
  @Output() campoOrdemEmt = new EventEmitter<string>();
  @Output() campoGrupoEmt = new EventEmitter<string>();
  @Output() agrupaTotalizadorEmt = new EventEmitter<boolean>();
  private util: Util = new Util();
  public bibDialogo = bibDialogo;
  public bibPropriedade = bibPropriedade;
  public bibIcone = bibIcone;
  public campoGrupo: string;
  public componente: any;
  public criterioNomes: Criterio[] = [];
  public criterios: Criterio[] = [];
  public cssUrl: string = 'assets/stylesheets/relatorio-retrato.css';
  public dadoEmpresa: boolean = true;
  public dataAtual = new Date();
  public iconeAgrupaTotalizador: string = bibIcone.olho;
  public linhaDivisoria: boolean = true;
  public matricial: boolean = false;
  public mensagemIconeAgrupaTotalizador: string = bibDialogo.ocultarResumir;
  public mostraAdicional: boolean;
  public nomeUsuario: string = this.utilSessao.getUsuario().nome;
  public retrato: boolean = true;
  public usaModoClaro: boolean = this.utilSessao.getUsuario() ? this.utilSessao.getUsuario().usaModoClaro == 'S' : false;
  public utilitarioFiltro = new Utilitario(bibIcone.filtro, this.bibDialogo.pesquisar + ' ' + this.bibDialogo.f2);

  public _idRelatorioInterno: number = null;
  @Input()
  set idRelatorioInterno(idRelatorioInterno: number) {
    this._idRelatorioInterno = idRelatorioInterno;
  }

  public _subTitulo: string = null;
  @Input()
  set subTitulo(subTitulo: string) {
    this._subTitulo = subTitulo;
  }

  @Input() relatorioResultados: any[];
  @Input() excel: string;
  constructor(public sanitizer: DomSanitizer, public utilSessao: UtilSessao, private modalNotificacao: ModalNotificacao, private utilNotificacao: UtilNotificacao, private titleService: Title, private router: Router) { }

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint(): void {
    this.removerModoEscuro();
    this.utilNotificacao.ehModoClaroEmmiter.emit(true);
  }

  @HostListener('window:afterprint', ['$event'])
  onAfterPrint(): void {
    this.carregarModoEscuro();
  }

  ngOnInit(): void {
    this.utilNotificacao.relatorioAdicionalEmitter.subscribe((relatorioAdicional) => {
      this.setRelatorioAdicionalRegra(relatorioAdicional);
    });
    this.utilNotificacao.acrescentaNomeEmt.subscribe((acrescentaNome) => {
      this.nomeAdicional = acrescentaNome;
    });
    this.utilNotificacao.dataAtualEmitter.subscribe((dataAtual) => {
      this.dataAtual = dataAtual;
      this._ordens.forEach((order) => order.id++);
      this.campoId = null;
    });
    this.utilNotificacao.modalRelatorioEmitter.subscribe((modalEmt) => {
      this.modal = modalEmt;
    });
    this.utilSessao.setPermissao(null);
    if (!this.usaModoClaro) {
      this.carregarModoEscuro();
    }
    this.utilNotificacao.agrupamento.subscribe((agrupamento) => {
      this.setCampoGrupo(agrupamento);
    });
    this.setRelatorioNomeSessao();
    this.utilitarios.push(new Utilitario(this.bibIcone.planilha, this.bibDialogo.excel, null, this.excel == null || this.excel == 'N' ? false : true));
    this.utilitarios.push(new Utilitario(this.bibIcone.arquivo, this.bibDialogo.opcoes, null, true, this._relatorio.opcao.toString()));
  }

  setRelatorioAdicionalRegra(relatorioAdicional: RelatorioAdicional): void {
    switch (relatorioAdicional.id) {
      case 1:
        this.retrato = relatorioAdicional.marcado == '' ? this.retrato : relatorioAdicional.marcado == 'S';
        let relatorioAdicionalNovo: RelatorioAdicional = this._relatorioAdicionais.find((relatorioAdicionalNovo) => relatorioAdicionalNovo.id == relatorioAdicional.id);
        this.alterarOrientacao(this.retrato);
        if (relatorioAdicionalNovo) {
          relatorioAdicionalNovo.marcado = relatorioAdicional.marcado == '' ? relatorioAdicionalNovo.marcado : relatorioAdicional.marcado;
          relatorioAdicionalNovo.mostraAdicional = relatorioAdicional.mostraAdicional;
        }
        break;
      case 2:
        this.linhaDivisoria = relatorioAdicional.marcado == 'S';
        break;
      case 3:
        this.dadoEmpresa = relatorioAdicional.marcado == 'S';
        break;
      case 4:
        this.matricial = relatorioAdicional.marcado == 'S';
        // this.adicionalEmt.emit(relatorioAdicional);
        break;
    }
  }

  alterarOrientacao(retrato: boolean): void {
    this.limparScripts();
    this.retrato = retrato;
    this.cssUrl = this.retrato ? 'assets/stylesheets/relatorio-retrato.css' : 'assets/stylesheets/relatorio-paisagem.css';
  }

  carregarModoEscuro(): void {
    if (!this.usaModoClaro) {
      const cssModoEscuro: string = 'assets/stylesheets/theme-dark.css';
      let elemento: any = document.createElement('link');
      elemento.rel = 'stylesheet';
      elemento.href = cssModoEscuro;
      document.getElementsByTagName('head')[0].appendChild(elemento);
      this.utilNotificacao.ehModoClaroEmmiter.emit(this.utilSessao.getUsuario().usaModoClaro == 'S');
    }
  }

  ehFiltro(): void {
    const criterios: Criterio[] = this.utilSessao.getRelatorioCriterios(this._relatorio.id);
    if (criterios) {
      this.filtroEmt.emit(criterios);
      this._relatorio.usaFiltro = false;
    } else {
      this.componente = FiltroComponent;
    }
  }

  setFiltroSessao(): void {
    if (this._filtros) {
      const filtros: Filtro[] = this.utilSessao.getFiltroRelatorio(this._relatorio.id);
      if (filtros.length == 0) {
        this.utilSessao.setFiltro(this._filtros);
      }
    }
  }

  setTituloAbaNavegador(): void {
    const id: string = this._relatorio.id.toString();
    const relatorioNome: string = this.util.definirPrimeiraMaiscula(this._relatorio.nome.toLowerCase());
    if (this.router.url == '/') {
      this.titleService.setTitle(this.utilSessao.sistema);
    } else {
      this.titleService.setTitle(id + ' - ' + relatorioNome);
    }
  }

  setRelatorioNomeSessao(): void {
    this.utilSessao.setRelatorioNome(this._relatorio.id + ' - ' + this._relatorio.nome);
  }

  setIdRelatorio() {
    if (this._filtros && this._relatorio) {
      this._filtros.forEach((filtro) => {
        filtro.idRelatorio = this._relatorio.id;
      });
      this.ehFiltro();
    }
  }

  limparScripts(): void {
    document.querySelectorAll('link').forEach((evento) => {
      if (evento.href.indexOf('relatorio-paisagem.css"') >= 0 || evento.href.indexOf('relatorio-retrato.css"') >= 0) {
        evento.remove();
      }
    });
  }

  setRelatorioAdicional(relatorioAdicional: RelatorioAdicional, inverteMarcado: boolean = true): void {
    if (inverteMarcado) {
      relatorioAdicional.marcado = relatorioAdicional.marcado == 'S' ? 'N' : 'S';
    }
    if (relatorioAdicional.id <= 100) {
      this.setRelatorioAdicionalRegra(relatorioAdicional);
    } else {
      this.adicionalEmt.emit(relatorioAdicional);
    }
  }

  setCriterios(criterios: Criterio[]): void {
    this.criterioNomes = criterios.filter((criterio) => criterio.valor != null && criterio.visualizacaoInicial);
    this.criterios = criterios;
    this.filtroEmt.emit(this.criterios);
  }

  setCampoOrdem(campoOrdem: string): void {
    this.campoOrdemEmt.emit(campoOrdem);
  }

  setCampoGrupo(campoGrupo: any): void {
    this.campoId = campoGrupo.id;
    this.campoGrupo = campoGrupo.campo;
    this.campoGrupoEmt.emit(campoGrupo);
  }

  agruparTotalizadores(): void {
    this.iconeAgrupaTotalizador = this.iconeAgrupaTotalizador == bibIcone.olhoBloqueado ? bibIcone.olho : bibIcone.olhoBloqueado;
    this.mensagemIconeAgrupaTotalizador = this.mensagemIconeAgrupaTotalizador == bibDialogo.ocultarResumir ? bibDialogo.mostrarExpandir : bibDialogo.ocultarResumir;
    this.agrupaTotalizadorEmt.emit();
  }

  removerModoEscuro(): void {
    document.querySelectorAll('[href="assets/stylesheets/theme-dark.css"').forEach((evento) => {
      evento.remove();
    });
  }

  verificarUtilitario(legenda: string): void {
    if (legenda == this.bibDialogo.opcoes) {
      this.mostraAdicional = this.mostraAdicional == true ? false : true;
    }
    if (legenda == this.bibDialogo.excel) {
      if (this.excel && this.excel == 'S') {
        if (confirm(bibDialogo.certezaArquivoExcel)) {
          this.exportarExcel();
        }
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.semPermissaoAcesso));
      }
    }
  }

  exportarExcel(): void {
    for (const relatorioResultado of this.relatorioResultados) {
      var atributos = Object.keys(relatorioResultado.lista[0]);
      let linha: string = this.criarLinhaTitulo(atributos);
      for (const dado of relatorioResultado.lista) {
        for (const atributo of atributos) {
          linha += this.tratarTipo(dado[atributo], typeof dado[atributo]) + ';';
        }
        linha += '\n';
      }
      this.downloadArquivo(linha, 'text/csv;charset=utf-8;', '.csv', this.criarNomeArquivo(this.relatorioResultados.length > 1 ? relatorioResultado.dado : null));
    }
  }

  criarLinhaTitulo(atributos: any): string {
    let linha: string = '';
    for (const atributo of atributos) {
      linha += this.tratarTitulo(atributo) + ';';
    }
    return linha + '\n';
  }

  tratarTitulo(atributo: any): string {
    let rotulo: string = '';
    for (let index = 0; index < atributo.length; index++) {
      let caracter: string = index == 0 ? atributo[index].toUpperCase() : atributo[index];
      if (index > 0) {
        if (caracter == caracter.toUpperCase()) {
          rotulo += ' ';
          caracter = caracter.toLowerCase();
        }
      }
      rotulo += caracter;
    }
    return rotulo;
  }

  tratarTipo(dado: string, tipo: string): string {
    if (tipo == null || tipo == undefined || dado == null || dado == undefined) {
      return '';
    }
    if (tipo == 'number') {
      return String(dado).replace('.', ',');
    }
    if (tipo == 'boolean') {
      return Boolean(dado) == true ? 'SIM' : 'NÃO';
    }
    if (dado?.toString().indexOf(':') > 0 && (dado?.toString().indexOf('AM') > 0 || dado?.toString().indexOf('PM') > 0 || dado?.toString().indexOf('GMT-') > 0)) {
      return new DataPipe().transform(new Date(dado)).replace("'", "");
    }
    while (dado?.toString().indexOf(';') > 0) {
      dado?.toString().replace(";", ",");
    }
    return dado;
  }

  criarNomeArquivo(nomeDataSet: string): string {
    return this._relatorio.id + ' - ' + this._relatorio.nome + (nomeDataSet ? ' [' + nomeDataSet + '] - ' : ' - ') + (new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()).replace("'", '').replace('/', '_') + ' - ' + this.nomeUsuario;
  }

  downloadArquivo(dados: any, tipo: string, extensao: string, nomeArquivo: string): void {
    const arquivo: Blob = new Blob([`\uFEFF${dados}`], { type: tipo });
    const elemento: any = window.document.createElement('a');
    elemento.href = window.URL.createObjectURL(arquivo);
    elemento.download = `${nomeArquivo}.${extensao}`;
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }

  ngOnDestroy(): void {
    this.utilSessao.excluirIdentificacao('idRelatorio');
  }

  ehFecharBotoesAdicionais(): void {
    if (this.mostraAdicional) {
      this.mostraAdicional = false;
    }
  }

  definirApelidoDoAdicional(): void {
    this._relatorioAdicionais.forEach((relatorioAdicional) => {
      if (relatorioAdicional.idMenu != null) {
        setTimeout((_) => {
          relatorioAdicional.nome = this.utilSessao
            .getMenuApelido()
            .find((menuEmpresa) => menuEmpresa.idMenu == relatorioAdicional.idMenu)
            .apelido.toLocaleUpperCase();
        });
      }
      if (relatorioAdicional.idNomenclatura != null) {
        setTimeout((_) => {
          relatorioAdicional.nome = this.utilSessao
            .getNomenclaturaEmpresas()
            .find((nomenclatura) => nomenclatura.id == relatorioAdicional.idNomenclatura)
            .apelido.toLocaleUpperCase();
        });
      }
    });
  }
}
